import { Box, Heading, Modal, Popover, useBreakpoint, useOpenClose } from '@hyphen/hyphen-components';
import { useLocalStorage } from '@uidotdev/usehooks';
import { IntegrationCatalogItem } from './integrations/catalog/IntegrationCatalogItem';
import { IntegrationType } from '../types/integrations';
import { useCreateIntegrationInviteMutation, useGetIntegrationsQuery } from '../services/integrations';
import { CompletedTask, UncompletedTaskButton, UncompletedTaskLink } from './integrations/IntegrationStatus';

export default function GetStarted({ organizationId }: { organizationId: string }) {
  const { isPhone } = useBreakpoint();
  const { isOpen: isPopoverOpen, handleToggle: togglePopover, handleClose: closePopover } = useOpenClose();
  const { data, isLoading } = useGetIntegrationsQuery(organizationId);
  const [CreateIntegrationInvite] = useCreateIntegrationInviteMutation();
  const [slackIntegration, setSlackIntegration] = useLocalStorage<boolean>('slackIntegration', undefined);

  const {
    isOpen: isProductivitySuiteOpen,
    handleToggle: toggleProductivitySuite,
    handleClose: closeProductivitySuite,
  } = useOpenClose();

  const {
    isOpen: isCloudProviderOpen,
    handleToggle: toggleCloudProvider,
    handleClose: closeCloudProvider,
  } = useOpenClose();

  const handleGetIntegrationInvite = async () => {
    const { data } = await CreateIntegrationInvite({
      organizationId: organizationId,
      integration: { type: IntegrationType.slack },
    });
    const url = data?.url;
    if (url) {
      window.open(url, '_blank');
      setSlackIntegration(true);
    }
  };

  const totalTasks = Object.keys(data?.integrationSetup || {}).length;
  const completedTasks =
    Object.values(data?.integrationSetup || {}).filter((task) => task).length +
    (!data?.integrationSetup.slack && slackIntegration ? 1 : 0);

  if (isLoading || completedTasks === totalTasks) {
    return null;
  }

  const content = (
    <>
      <Box gap="xl">
        <Box gap="xs">
          <Heading
            as="h4"
            size={{ base: 'sm', tablet: 'md' }}
            style={{
              backgroundImage: 'var(--color-background-brand-gradient)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              fontWeight: 'bold',
            }}
          >
            Get Started
          </Heading>
          <Box fontSize="xs" color="secondary">
            Get the most out of Hyphen by connecting to your organization's tools
          </Box>
        </Box>
        <Box gap="sm" fontSize="xs">
          {data?.integrationSetup.slack || slackIntegration ? (
            <CompletedTask
              name="Install Hyphen Bot"
              onClick={handleGetIntegrationInvite}
              closePopover={closePopover}
            />
          ) : (
            <UncompletedTaskButton
              name="Install Hyphen Bot"
              onClick={handleGetIntegrationInvite}
              closePopover={closePopover}
            />
          )}
          {data?.integrationSetup.cloudProvider ? (
            <CompletedTask name="Connect Cloud Provider" />
          ) : (
            <UncompletedTaskButton
              name="Connect Cloud Provider"
              onClick={toggleCloudProvider}
              closePopover={closePopover}
            />
          )}
          {data?.integrationSetup.productivitySuite ? (
            <CompletedTask name="Connect Productivity Suite" />
          ) : (
            <UncompletedTaskButton
              name="Connect Productivity Suite"
              onClick={toggleProductivitySuite}
              closePopover={closePopover}
            />
          )}
          {data?.integrationSetup.github ? (
            <CompletedTask name="Connect Github" />
          ) : (
            <UncompletedTaskLink
              name="Connect Github"
              linkPath="integrations/setup/github"
              closePopover={closePopover}
            />
          )}
        </Box>
      </Box>
    </>
  );

  if (isPhone) {
    return (
      <Box radius="lg" padding="xl" className="frosted-glass" shadow="xs" margin="xl 0 0 0">
        {content}
      </Box>
    );
  }

  return (
    <>
      <Modal
        isOpen={isProductivitySuiteOpen}
        onClose={closeProductivitySuite}
        title="Select your productivity suite"
        onDismiss={closeProductivitySuite}
        maxWidth="9xl"
      >
        <Modal.Body gap="lg">
          <Box as="h4" fontSize="md">
            Select your productivity suite
          </Box>
          <Box as="p" color="secondary">
            Keep team membership synced for up-to-date document access, calendaring and distribution lists.
          </Box>
          <Box gap="lg" margin="lg 0">
            <IntegrationCatalogItem
              organizationId={organizationId}
              onClick={closeProductivitySuite}
              hideDescription
              type={IntegrationType.googleWorkspace}
            />
            <IntegrationCatalogItem
              organizationId={organizationId}
              onClick={closeProductivitySuite}
              hideDescription
              type={IntegrationType.office365}
              comingSoon
            />
          </Box>
        </Modal.Body>
      </Modal>

      <Modal
        isOpen={isCloudProviderOpen}
        onClose={closeCloudProvider}
        title="Select your cloud provider"
        onDismiss={closeCloudProvider}
        maxWidth="9xl"
      >
        <Modal.Body gap="lg">
          <Box as="h4" fontSize="md">
            Select your cloud provider
          </Box>
          <Box as="p" color="secondary">
            Automate your team's infrastructure and ensure resource access is automatically kept in sync with team
            membership
          </Box>
          <Box gap="lg" margin="lg 0">
            <IntegrationCatalogItem
              organizationId={organizationId}
              onClick={closeCloudProvider}
              type={IntegrationType.aws}
              hideDescription
            />
            <IntegrationCatalogItem
              organizationId={organizationId}
              onClick={closeCloudProvider}
              type={IntegrationType.azure}
              hideDescription
            />
            <IntegrationCatalogItem
              organizationId={organizationId}
              onClick={closeCloudProvider}
              type={IntegrationType.googleCloud}
              hideDescription
            />
          </Box>
        </Modal.Body>
      </Modal>

      <Popover
        isOpen={isPopoverOpen}
        content={content}
        placement="right"
        onClickOutside={closePopover}
        contentContainerProps={{ padding: '3xl', radius: 'xl', width: '280px', className: 'frosted-glass' }}
        hasArrow={false}
        offsetFromTarget={2}
      >
        <Box
          display="block"
          as="button"
          background="brand-gradient"
          onClick={togglePopover}
          padding="lg xl"
          fontWeight="bold"
          color="white"
          borderWidth="0"
          radius="sm"
          textAlign="center"
          shadow="sm"
          cursor="pointer"
        >
          {`Get Started ${completedTasks}/${totalTasks}`}
        </Box>
      </Popover>
    </>
  );
}
