import React from 'react';
import { Box, BoxProps } from '@hyphen/hyphen-components';

const MainCard = (props: BoxProps): JSX.Element => {
  const { children, ...restProps } = props;
  return (
    <Box
      borderColor="subtle"
      borderWidth={{ base: 'sm 0 0 0', desktop: 'sm' }}
      height="100"
      radius={{ base: '0', desktop: 'md' }}
      shadow="2xs"
      overflow="hidden"
      alignItems="center"
      background="primary"
      {...restProps}
    >
      {children}
    </Box>
  );
};

export default MainCard;
