import { Box, useOpenClose } from '@hyphen/hyphen-components';
import { Route, Routes } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import Nav from './profile/nav';
import { MyProfile } from './profile/MyProfile';
import { Notifications } from './profile/Notifications';
import MobileHeader from './MobileHeader';
import MainCard from './MainCard';
import { ScrollableMainLayout } from './ScrollableMainLayout';

export function Profile() {
  const {
    isOpen: isMainNavOpen,
    handleClose: handleMainNavClose,
    handleToggle: handleMainNavToggle,
  } = useOpenClose();

  return (
    <>
      <Helmet>
        <title>My Profile</title>
      </Helmet>

      <Box
        direction={{ base: 'column', desktop: 'row' }}
        width="100"
        height="100"
        minHeight="100"
        margin="0"
        alignItems="stretch"
      >
        <Nav handleMainNavClose={handleMainNavClose} isMainNavOpen={isMainNavOpen} />
        <Box
          as="main"
          style={{ flex: '1 1 auto' }}
          width="100"
          height="100"
          direction="column"
          padding={{ base: '0', desktop: 'xl xl xl 0' }}
          overflow="hidden"
        >
          <MobileHeader handleToggle={handleMainNavToggle} />
          <MainCard>
            <ScrollableMainLayout>
              <Routes>
                <Route path="/" element={<MyProfile />} />
                <Route path="/notifications" element={<Notifications />} />
              </Routes>
            </ScrollableMainLayout>
          </MainCard>
        </Box>
      </Box>
    </>
  );
}
