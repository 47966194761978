import { Box, Button, useOpenClose } from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../../components/PageHeader';
import { MemberList } from '../../components/members/MemberList';
import { InviteMemberModal } from '../../components/members/InviteMemberModal';
import { Organization } from '../../services/organization';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useLocation } from 'react-router-dom';
import { useOrganizationAbilityContext } from '../../components/auth/OrganizationAbilityProvider';
import { EntityNames } from '../../types/executionContext';

export default function Members() {
  const { organization = {} as Organization } = useOrganization();
  const location = useLocation();
  const ability = useOrganizationAbilityContext();
  const canInviteMember = ability.can('create', EntityNames.Member);

  const { isOpen: isInviteOpen, handleToggle: toggleInvite, handleClose: closeInvite } = useOpenClose({ defaultIsOpen: location.search === '?invite' });

  return (
    <Box width="100" maxWidth="9xl" gap="4xl" alignItems="flex-start">
      <Helmet>
        <title>Members</title>
      </Helmet>
      <PageHeader title="Members" description={`Manage who has access to this organization`} />
      {canInviteMember && (
        <Button variant="primary" iconPrefix="add" onClick={toggleInvite}>
          Invite Member
        </Button>
      )}
      <MemberList />
      <InviteMemberModal isOpen={isInviteOpen} onClose={closeInvite} data={{ organization }} />
    </Box>
  );
}
