import { api } from './api';

export interface BillingAccountResponse {
  id: string;
  name: string;
  email: string;
  phone?: string;
  address: {
    streetAddress: string;
    locality: string;
    region: string;
    postalCode: string;
    country: string;
  };
  provider: string;
  providerId: string;
  providerData: any;
  paymentMethods: {
    providerId: string;
    type: 'Card' | 'ACH';
    last4?: string;
  }[];
}

export const billingAccountApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBillingAccountById: builder.query<BillingAccountResponse, string>({
      query: (id) => `api/billing-accounts/${id}`,
      providesTags: (result) => (result ? [{ type: 'BillingAccounts', id: result.id }] : []),
    }),
  }),
});

export const { useGetBillingAccountByIdQuery } = billingAccountApi;
