import { Box, Button, Heading, Icon, Spinner, useOpenClose } from '@hyphen/hyphen-components';
import {
  INTEGRATION_DESCRIPTION_MAP,
  INTEGRATION_ICON_MAP,
  INTEGRATION_NAME_MAP,
} from '../../../constants/integrations';
import { Integration, IntegrationType } from '../../../types/integrations';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import GithubDetails from '../../../components/integrations/details/GithubDetails';
import GoogleWorkspaceDetails from '../../../components/integrations/details/GoogleWorkspaceDetails';
import { Helmet } from 'react-helmet';
import { Organization } from '../../../services/organization';
import PageHeader from '../../../components/PageHeader';
import { ScrollableMainLayout } from '../../../components/ScrollableMainLayout';
import { useGetIntegrationsQuery } from '../../../services/integrations';
import { useOrganization } from '../../../providers/OrganizationProvider';
import GoogleCloudDetails from '../../../components/integrations/details/GoogleCloudDetails';
import AzureDetails from '../../../components/integrations/details/AzureDetails';
import AwsDetails from '../../../components/integrations/details/AwsDetails';
import { GoogleCloudSetupGuide } from '../../../components/integrations/setup/googleCloud/GoogleCloudSetupGuide';
import { GoogleWorkspaceSetupGuide } from '../../../components/integrations/setup/googleWorkspace/GoogleWorkspaceSetupGuide';
import { AzureSetupGuide } from '../../../components/integrations/setup/azure/AzureSetupGuide';
import { AwsSetupGuide } from '../../../components/integrations/setup/aws/AwsSetupGuide';

export const IntegrationDetails = () => {
  const { organization = {} as Organization } = useOrganization();
  const { id: integrationId } = useParams<{ id: any }>();
  const { isLoading, data: integrationsResponse } = useGetIntegrationsQuery(organization.id);
  const [integration, setIntegration] = useState<Integration | null>(null);
  useEffect(() => {
    if (!isLoading) {
      const found = integrationsResponse?.data?.find((i) => i.id === integrationId);
      setIntegration(found || null);
    }
  }, [isLoading, integrationsResponse, integrationId]);

  const { isOpen, handleToggle } = useOpenClose();

  return (
    <ScrollableMainLayout>
      {isLoading && <Spinner size="lg" />}
      {!isLoading && !integration && <IntegrationNotFound id={integrationId} orgId={organization.id} />}
      {!!integration && (
        <>
          <Helmet>
            <title>{INTEGRATION_NAME_MAP[integration.type]}</title>
          </Helmet>
          <Box direction="row" gap="xl">
            <PageHeader
              eyebrow={<Link to={`/${organization.id}/integrations`}>Integrations</Link>}
              title={`${INTEGRATION_NAME_MAP[integration.type]}`}
              description={INTEGRATION_DESCRIPTION_MAP[integration.type]}
              iconUrl={INTEGRATION_ICON_MAP[integration.type]}
            />
          </Box>

          <Box gap="5xl">
            {integration?.type === IntegrationType.googleWorkspace && (
              <>
                <GoogleWorkspaceDetails integration={integration} />
                {isOpen && <GoogleWorkspaceSetupGuide organizationId={organization.id} />}
              </>
            )}
            {integration?.type === IntegrationType.github && <GithubDetails integration={integration} />}
            {integration?.type === IntegrationType.googleCloud && (
              <>
                <GoogleCloudDetails integration={integration} />
                {isOpen && <GoogleCloudSetupGuide organizationId={organization.id} />}
              </>
            )}
            {integration?.type === IntegrationType.azure && (
              <>
                <AzureDetails integration={integration} />
                {isOpen && <AzureSetupGuide organizationId={organization.id} />}
              </>
            )}
            {integration?.type === IntegrationType.aws && (
              <>
                <AwsDetails integration={integration} />
                {isOpen && <AwsSetupGuide organizationId={organization.id} />}
              </>
            )}
          </Box>

          <Box alignSelf="flex-start">
            <Button onClick={handleToggle} variant="secondary">
              {`${isOpen ? 'Hide' : 'Show'} Setup Guide`}
            </Button>
          </Box>
        </>
      )}
    </ScrollableMainLayout>
  );
};

function IntegrationNotFound({ id, orgId }: { id: string; orgId: string }) {
  return (
    <Box margin={{ base: 'lg auto 5xl', desktop: '2xl auto 5xl' }} width={{ base: '100', desktop: '80' }}>
      <Box
        padding={{ base: 'lg', desktop: '0' }}
        childGap={{
          base: 'lg',
        }}
        height="100"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <Box fontSize={{ base: '2xl', desktop: '3xl' }} color="secondary" aria-hidden="true">
          ¯\_(ツ)_/¯
        </Box>
        <Heading as="h1" size={{ base: 'lg', desktop: 'xl' }}>
          We couldn't find the integration you're looking for.
        </Heading>
        <Box as="p">Integration with the ID `{id}` not found</Box>
        <Link to={`/${orgId}/integrations`}>
          <Box alignItems="center" direction="row" gap="xs">
            <Icon name="caret-sm-left" /> Integrations
          </Box>
        </Link>
      </Box>
    </Box>
  );
}

export default IntegrationDetails;
