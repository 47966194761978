import '@hyphen/hyphen-components/dist/css/reset.css';
import '@hyphen/hyphen-components/dist/css/utilities.css';
import '@hyphen/hyphen-components/dist/css/variables.css';
import '@hyphen/hyphen-components/dist/css/index.css';
import '@hyphen/hyphen-components/dist/css/fonts.css';
import './App.css';

import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { AuthProvider } from './components/auth/authProvider';
import Error from './Error';
import FocusedLayout from './layouts/FocusedLayout';
import { OrganizationBase } from './pages/OrganizationBase';
import { OrganizationList } from './pages/OrganizationList';
import { OrganizationsListProvider } from './providers/OrganizationsListProvider';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { ResponsiveProvider, ThemeProvider, ToastContainer } from '@hyphen/hyphen-components';
import { Profile } from './components/Profile';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import { IntegrationSetup } from './pages/Integrations/IntegrationSetup';
import { AccountCreate } from './pages/GetStarted/AccountCreate';
import { AccountSetup } from './pages/GetStarted/AccountSetup';
import { Helmet } from 'react-helmet';
import TagManager from '@sooro-io/react-gtm-module';
import { ErrorPage } from './pages/Error';

if (process.env.REACT_APP_GTM_ID) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    ...(process.env.REACT_APP_GTM_AUTH ? { auth: process.env.REACT_APP_GTM_AUTH } : {}),
    ...(process.env.REACT_APP_GTM_ENV ? { preview: process.env.REACT_APP_GTM_ENV } : {}),
  };

  TagManager.initialize(tagManagerArgs);
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

export const routes = {
  base: '/',
  getStarted: 'get-started',
  integrations: 'integrations',
  integrationSetup: 'integrations/setup/:integrationType',
  profile: 'profile',
  error: 'error'
};

const router = createBrowserRouter([
  {
    path: `${routes.base}`,
    errorElement: <Error />,
    children: [
      {
        element: <FocusedLayout />,
        children: [
          {
            path: `${routes.getStarted}`,
            element: <AccountCreate />,
          },
          {
            path: `${routes.getStarted}/:billingAccountId`,
            element: <AccountSetup />,
          },
        ],
      },
      {
        element: <FocusedLayout />,
        children: [
          {
            path: `${routes.integrationSetup}`,
            element: <IntegrationSetup />,
          },
        ],
      },
      {
        children: [
          {
            path: `${routes.profile}/*`,
            element: <Profile />,
          },
          {
            path: `:id/*`,
            element: <OrganizationBase />,
          },
          {
            index: true,
            element: <OrganizationList />,
          },
        ],
      },
      {
        path: routes.error,
        element: <ErrorPage />,
      }
    ],
  },
]);

(async (): Promise<void> => {
  root.render(
    <Provider store={store}>
      <ThemeProvider storageKey="engine-ui-theme">
        <AuthProvider>
          <Helmet titleTemplate="%s | Hyphen"></Helmet>
          <OrganizationsListProvider>
              <ResponsiveProvider>
                <ToastContainer />
                <RouterProvider router={router} />
              </ResponsiveProvider>
          </OrganizationsListProvider>
        </AuthProvider>
      </ThemeProvider>
    </Provider>,
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
