import React from 'react';
import { Box, Button, useBreakpoint } from '@hyphen/hyphen-components';

type Props = {
  name?: string;
  handleToggle: () => void;
};

// @ts-ignore
const MobileHeader: React.FC<Props> = ({ name, handleToggle }) => {
  const { isPhone, isTablet } = useBreakpoint();

  const isMobile = isPhone || isTablet;

  if (isMobile) {
    return (
      <Box borderWidth="0 0 xs 0" padding="sm lg" alignItems="flex-start" borderColor="default">
        <Button
          variant="tertiary"
          iconPrefix="menu"
          size="sm"
          onClick={handleToggle}
          style={{ zIndex: 'var(--size-z-index-popover)' }}
        />
      </Box>
    );
  }
  return;
};

export default MobileHeader;
