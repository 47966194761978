import { Box, Icon } from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';

export const CompletedTask = ({ name, onClick, closePopover }: { name: string, onClick?: () => void, closePopover?: () => void }) => {
  const handler = onClick ? () => {
    onClick();
    if (closePopover)
      closePopover();
  } : null;


  return (
    <Box
      {...(handler ? { as: "button", onClick: handler, cursor: "pointer", borderColor: "subtle", borderWidth: "sm" } : {})}
      radius="md"
      padding="lg"
      background="secondary"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      color="tertiary"
    >
      <span style={{ textDecoration: 'line-through' }}>{name}</span>
      <Icon name="c-check" color="disabled" size="md" />
    </Box>
  );
}

export const UncompletedTaskButton = ({ name, onClick, closePopover }: {
  name: string,
  onClick: () => void,
  closePopover: () => void
}) => (
  <Box
    as="button"
    onClick={() => {
      onClick();
      closePopover();
    }}
    color="base"
    cursor="pointer"
    radius="md"
    padding="lg"
    background="primary"
    borderColor="subtle"
    borderWidth="sm"
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    hover={{ background: 'secondary', borderColor: 'default' }}
  >
    {name}
    <Icon name="circle" color="tertiary" size="md" />
  </Box>
);

export const UncompletedTaskLink = ({ name, linkPath, closePopover }: { name: string, linkPath: string, closePopover: () => void }) => (
  <Link to={linkPath} onClick={closePopover} style={{ textDecoration: 'none' }}>
    <Box
      radius="md"
      padding="lg"
      background="primary"
      borderColor="subtle"
      borderWidth="sm"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      hover={{ background: 'secondary', borderColor: 'default' }}
    >
      {name}
      <Icon name="circle" color="tertiary" size="md" />
    </Box>
  </Link>
)
