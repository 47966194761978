import { Box, Button, Card, Heading } from '@hyphen/hyphen-components';

export const DeleteItemCard = ({ itemLabel = 'item', onDelete }: { itemLabel: string; onDelete: () => void }) => (
  <Card borderColor="danger">
    <Card.Section>
      <Box direction="row" gap="2xl" justifyContent="space-between" alignItems="center">
        <Box gap="2xs">
          <Heading as="h4" size="sm">
            {`Delete ${itemLabel}`}
          </Heading>
          <Box color="secondary" fontSize="sm">
            Once deleted, it will be gone forever. Please be certain.
          </Box>
        </Box>
        <Button variant="danger" onClick={onDelete}>
          Delete
        </Button>
      </Box>
    </Card.Section>
  </Card>
);
