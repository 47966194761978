import {
  CloudProvider,
  Integration,
  IntegrationType,
  ProductivitySuite,
  SlackIntegration,
  IntegrationSetup,
} from '../types/integrations';
import { ListResponse } from './types';
import { api } from './api';

export const integrationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getIntegrations: build.query<ListResponse<Integration>& {integrationSetup: IntegrationSetup }, string>({
      query: (orgId: string) => ({ url: `api/organizations/${orgId}/integrations` }),
      transformResponse(baseQueryReturnValue: ListResponse<Integration>, meta = undefined, arg: string): ListResponse<Integration>& {integrationSetup: IntegrationSetup } | Promise<ListResponse<Integration>& {integrationSetup: IntegrationSetup}> {
        const integrationSetup: IntegrationSetup = {
          productivitySuite: false,
          cloudProvider: false,
          github: false,
          slack: false
        };

        baseQueryReturnValue.data.forEach(integration => {
          if (Object.values(ProductivitySuite).includes(integration.type)) {
            integrationSetup.productivitySuite = true;
          }
          if (Object.values(CloudProvider).includes(integration.type)) {
            integrationSetup.cloudProvider = true;
          }
          if (integration.type === IntegrationType.github) {
            integrationSetup.github = true;
          }
        });

        return {
          ...baseQueryReturnValue,
          data: [...baseQueryReturnValue.data],
          integrationSetup,
        };
      },
      providesTags: (result, _error, orgId) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Integrations' as const, id })),
              { type: 'Integrations', id: `${orgId}-LIST` },
            ]
          : [],
    }),
    createIntegration: build.mutation<Integration, { orgId: string; integration: Omit<Integration, 'id'> }>({
      query: ({ orgId, integration }) => ({
        url: `api/organizations/${orgId}/integrations`,
        method: 'POST',
        body: integration,
      }),
      invalidatesTags: (_result, _error, { orgId }) => [{ type: 'Integrations', id: `${orgId}-LIST` }],
    }),
    testIntegration: build.mutation<unknown, { orgId: string; integration: Omit<Integration, 'id'> }>({
      query: ({ orgId, integration }) => ({
        url: `api/organizations/${orgId}/integrations/test`,
        method: 'POST',
        body: integration,
      }),
    }),
    deleteIntegration: build.mutation<void, { orgId: string; integrationId: string }>({
      query: ({ orgId, integrationId }) => ({
        url: `api/organizations/${orgId}/integrations/${integrationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { orgId, integrationId }) => [
        { type: 'Integrations', id: integrationId },
        { type: 'Integrations', id: `${orgId}-LIST` },
      ],
    }),
    createIntegrationInvite: build.mutation<SlackIntegration, { organizationId: string; integration: Omit<SlackIntegration, 'url'> }>({
      query: ({ organizationId, integration }) => ({
        url: `api/organizations/${organizationId}/integration-invites`,
        method: 'POST',
        body: integration,
      }),
    }),
  }),
});

export const {
  useGetIntegrationsQuery,
  useCreateIntegrationMutation,
  useDeleteIntegrationMutation,
  useTestIntegrationMutation,
  useCreateIntegrationInviteMutation,
} = integrationsApi;
