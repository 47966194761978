import './nav.scss';

import { Box, Drawer, Icon, useBreakpoint } from '@hyphen/hyphen-components';
import { Link, NavLink, useLocation } from 'react-router-dom';
import MainNavItem from '../MainNavItem';
import { useOrganizationsList } from '../../providers/OrganizationsListProvider';

function Nav({
  isMainNavOpen,
  handleMainNavClose,
}: {
  className?: string;
  isMainNavOpen: boolean;
  handleMainNavClose: () => void;
}) {
  const { isPhone, isTablet } = useBreakpoint();
  const location = useLocation();

  let backOrgId = location.state?.backOrgId;

  const { organizations = [], isLoading: areOrganizationsLoading } = useOrganizationsList();

  // default to the first organization the user has access to
  if (!backOrgId) {
    backOrgId = organizations[0]?.id;
  }

  const isMobile = isPhone || isTablet;

  if (isMobile) {
    return (
      <Drawer isOpen={isMainNavOpen} onDismiss={handleMainNavClose} placement="left">
        <Box height="100" padding="5xl 0 lg lg" gap="md" background="primary" as="nav" fontSize="sm">
          <Box padding="md">
            {areOrganizationsLoading ? (
              <Box background="tertiary" width="100" height="2xl" />
            ) : (
              <Link to={`/${backOrgId}`} className="display-flex align-items-center flex-direction-row g-md">
                <Icon name="caret-left" aria-label="back to accounts" />
                {organizations.find((org) => org.id === backOrgId)?.name}
              </Link>
            )}
          </Box>

          <Box flex="auto" gap="2xs" overflow="auto" className="scroll-bar-thin" padding="0 lg 5xl 0">
            <NavLink end className="navlink" to="/profile" onClick={handleMainNavClose}>
              <MainNavItem iconName="user">Profile</MainNavItem>
            </NavLink>
            {/* <NavLink className="navlink" to="/profile/notifications" onClick={handleMainNavClose}>
              <MainNavItem iconName="alarm">Notifications</MainNavItem>
            </NavLink>
            <NavLink className="navlink" to="/profile/security" onClick={handleMainNavClose}>
              <MainNavItem iconName="lock">Security</MainNavItem>
            </NavLink> */}
          </Box>
        </Box>
      </Drawer>
    );
  }

  return (
    <Box
      width="8xl"
      minWidth="8xl"
      height="100"
      padding={{ base: '5xl 0 lg lg', desktop: 'lg 0 lg lg' }}
      position={isMobile ? 'absolute' : 'relative'}
      background={isMobile ? 'primary' : 'secondary'}
      shadow={isMobile ? 'sm' : undefined}
      as="nav"
      fontSize="sm"
      gap="md"
    >
      <Box padding="md">
        {areOrganizationsLoading ? (
          <Box background="tertiary" width="100" height="2xl" />
        ) : (
          <Link to={`/${backOrgId}`} className="display-flex align-items-center flex-direction-row g-md">
            <Icon name="caret-left" aria-label="back to accounts" />
            {organizations.find((org) => org.id === backOrgId)?.name}
          </Link>
        )}
      </Box>

      <Box flex="auto" gap="2xs" overflow="auto" className="scroll-bar-thin" padding="0 lg 5xl 0">
        <NavLink end className="navlink" to="/profile" onClick={handleMainNavClose} state={{ backOrgId: backOrgId }}>
          <MainNavItem iconName="user">Profile</MainNavItem>
        </NavLink>
        {/* <NavLink
          className="navlink"
          to="/profile/notifications"
          onClick={handleMainNavClose}
          state={{ backOrgId: backOrgId }}
        >
          <MainNavItem iconName="alarm">Notifications</MainNavItem>
        </NavLink>
        <NavLink
          className="navlink"
          to="/profile/security"
          onClick={handleMainNavClose}
          state={{ backOrgId: backOrgId }}
        >
          <MainNavItem iconName="lock">Security</MainNavItem>
        </NavLink> */}
      </Box>
    </Box>
  );
}

export default Nav;
