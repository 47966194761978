export enum IntegrationType {
  googleWorkspace = 'googleWorkspace',
  github = 'github',
  googleCloud = 'googleCloud',
  azure = 'azure',
  aws = 'aws',
  slack = 'slack',
  office365 = 'office365',
}

export interface AwsIntegrationConfig {
  region: string;
  managementAccountId: string;
  name: string;
}

export interface AwsIntegrationOptions extends Record<string, any> {}

export interface AzureIntegrationConfig {
  azureTenantId: string;
  name: string;
}

export interface AzureIntegrationOptions extends Record<string, any> {}

export interface GoogleWorkspaceIntegrationConfig {
  domain: string;
  adminUserEmail: string;
}

export interface GoogleWorkspaceIntegrationOptions extends Record<string, any> {}

export interface githubIntegrationConfig {
  installationId: string;
  githubOrgId: string;
  githubOrgName: string;
}

export interface GithubIntegrationOptions extends Record<string, any> {}

export interface GoogleCloudIntegrationConfig {
  googleCloudOrganizationId: string;
  googleCloudOrganizationName: string;
}

export interface GoogleCloudIntegrationOptions extends Record<string, any> {}

export interface BaseIntegration {
  id: string;
  type: IntegrationType;
  config: unknown;
  options: unknown;
}

export interface AzureIntegration extends BaseIntegration {
  type: IntegrationType.azure;
  config: AzureIntegrationConfig;
  options: AzureIntegrationOptions;
}

export interface AwsIntegration extends BaseIntegration {
  type: IntegrationType.aws;
  config: AwsIntegrationConfig;
  options: AwsIntegrationOptions;
}

export interface GoogleWorkspaceIntegration extends BaseIntegration {
  type: IntegrationType.googleWorkspace;
  config: GoogleWorkspaceIntegrationConfig;
  options: GoogleWorkspaceIntegrationOptions;
}

export interface GithubIntegration extends BaseIntegration {
  type: IntegrationType.github;
  config: githubIntegrationConfig;
  options: GithubIntegrationOptions;
}

export interface GoogleCloudIntegration extends BaseIntegration {
  type: IntegrationType.googleCloud;
  config: GoogleCloudIntegrationConfig;
  options: GoogleCloudIntegrationOptions;
}

export interface SlackIntegration {
  type: IntegrationType.slack;
  url: string;
}

export type Integration =
  | GoogleWorkspaceIntegration
  | GithubIntegration
  | GoogleCloudIntegration
  | AzureIntegration
  | AwsIntegration;

export function isGoogleWorkspaceIntegration(integration: Integration): integration is GoogleWorkspaceIntegration {
  return integration.type === IntegrationType.googleWorkspace;
}

export function isGithubIntegration(integration: Integration): integration is GithubIntegration {
  return integration.type === IntegrationType.github;
}

export function isGoogleCloudIntegration(integration: Integration): integration is GoogleCloudIntegration {
  return integration.type === IntegrationType.googleCloud;
}

export enum ProductivitySuite {
  googleWorkspace = IntegrationType.googleWorkspace,
  office365 = IntegrationType.office365,
}

export enum CloudProvider {
  aws = IntegrationType.aws,
  azure = IntegrationType.azure,
  googleCloud = IntegrationType.googleCloud,
}

export type IntegrationSetupKeys = 'productivitySuite' | 'cloudProvider' | 'github' | 'slack';
export type IntegrationSetup = Record<IntegrationSetupKeys, boolean>;
