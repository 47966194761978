import { Box } from '@hyphen/hyphen-components';

const ComingSoon = () => (
  <Box
    background="brand-yellow"
    padding="xs md"
    radius="lg"
    fontSize="2xs"
    fontFamily="monospace"
    color="grey"
    display="inline-block"
  >
    COMING SOON
  </Box>
);

export default ComingSoon;
