import { Box, Pagination, Spinner, Table } from "@hyphen/hyphen-components";
import { useGetMembersQuery } from "../../services/member";
import { useCallback } from "react";
import { useOrganization } from "../../providers/OrganizationProvider";
import { Organization } from "../../services/organization";
import useQueryStringState from "../../hooks/useQueryStringState";

export const MemberList = () => {
  const { organization = {} as Organization } = useOrganization();
  const [qsState, setQsState] = useQueryStringState({ pageNum: 1, pageSize: 50 });
  const { data: members, isLoading } = useGetMembersQuery({ organizationId: organization.id, pageNum: qsState.pageNum, pageSize: qsState.pageSize });
  const handlePageNumberChange = useCallback((pageNum: number) => {
    setQsState({ pageNum, pageSize: qsState.pageSize });
  }, [qsState.pageSize, setQsState]);


  const columnConfig = [
    {
      heading: 'Name',
      dataKey: 'nickname',
    },
    {
      heading: 'Email',
      dataKey: 'email',
    },
    {
      heading: 'Connections',
      dataKey: 'connectedAccounts',
      render: (connectedAccounts: any) => connectedAccounts?.length || 0,
    }
  ]

  return isLoading ? <Box><Spinner /></Box> : (
    <Box width="100" gap="lg">
      <Table
        rowKey="id"
        isLoading={isLoading}
        columns={columnConfig}
        rows={members?.data || []}
      />
      {members?.total && members?.total > qsState.pageSize ? (
        <Pagination
          activePage={qsState.pageNum}
          itemsPerPage={qsState.pageSize}
          onChange={handlePageNumberChange}
          totalItemsCount={members?.total ?? 0}
          numberOfPagesDisplayed={3}
          isCompact
          arePagesVisible
        />
      ) : null}
    </Box>
  );
};