import { Box, Button, FormikTextInput, Modal, toast } from '@hyphen/hyphen-components';
import { Formik, Field, Form } from 'formik';
import { ApiError } from '../ApiError';
import { useCallback, useRef } from 'react';
import * as yup from 'yup';
import { InferType } from 'yup';
import { useCreateMemberMutation } from '../../services/member';
import { ModalProps } from '../types/modal';

const inviteMemberSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
});

type InviteMemberSchema = InferType<typeof inviteMemberSchema>;

const errorMessages = {
  default: 'Failed to invite member',
};

export const InviteMemberModal = ({ isOpen, onClose, data }: ModalProps) => {
  const [createMember, { error }] = useCreateMemberMutation();
  const containerRef = useRef<HTMLDivElement>(null);

  const handleOnSubmit = useCallback(
    async (values: InviteMemberSchema) => {
      const { error, data: responseData } = await createMember({
        email: values.email,
        organizationId: data.organization.id,
      });

      if (!error && responseData) {
        toast.success('Member Invited', { duration: 5000 });
        onClose();
      }
    },
    [createMember, data.organization.id, onClose],
  );

  return (
    <Modal
      ariaLabelledBy="inviteMemberModal"
      isOpen={isOpen}
      onDismiss={onClose}
      maxWidth="9xl"
      containerRef={containerRef}
    >
      <Formik initialValues={{ email: '' }} validationSchema={inviteMemberSchema} onSubmit={handleOnSubmit}>
        {({ isSubmitting, errors }) => (
          <Form noValidate>
            <Box gap={{ base: '2xl', tablet: '4xl' }}>
              <Modal.Header id="inviteMemberModal" title="Invite Member" onDismiss={onClose} />
              <Modal.Body gap="xl">
                <Field
                  id="email"
                  name="email"
                  label="Email"
                  component={FormikTextInput}
                  error={errors.email}
                  isRequired
                />
                {error ? <ApiError error={error} customMessages={errorMessages} /> : null}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={onClose} isDisabled={isSubmitting}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" isLoading={isSubmitting}>
                  Invite
                </Button>
              </Modal.Footer>
            </Box>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
