import { Outlet } from 'react-router-dom';
import { Box, Button, useTheme } from '@hyphen/hyphen-components';
import { useAuth } from '../components/auth/authProvider';

export default function FocusedLayout() {
  const { user } = useAuth();
  const { isDarkMode } = useTheme();

  const bgGradient = isDarkMode
    ? 'linear-gradient(180deg, #232323 0%, #1C1C1C 25%, #0D0D0D 50%)'
    : 'linear-gradient(180deg, #FFF 0%, #F8F8F8 31.59%);';

  return (
    <Box width="100" height="100" style={{ background: bgGradient }}>
      <Box direction="row" justifyContent="space-between" padding="2xl">
        <Box gap="xs">
          <Box fontSize="xs" color="secondary">
            Logged in as
          </Box>
          <Box fontSize="sm"> {user?.name}</Box>
        </Box>
        <Button variant="secondary" size="sm">
          Log out
        </Button>
      </Box>
      <Outlet />
    </Box>
  );
}
