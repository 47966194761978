import { Box, Card, Alert, Spinner } from '@hyphen/hyphen-components';
import { LabelValue } from '../LabelValue';
import { Organization, useGetOrganizationBillingAccountQuery } from '../../services/organization';
import { useOrganization } from '../../providers/OrganizationProvider';

export const ViewBillingAccount = () => {
  const { organization = {} as Organization } = useOrganization();
  const { isLoading, data: billingAccount } = useGetOrganizationBillingAccountQuery(organization.id);

  return (
    <>
      { isLoading && <Spinner /> }
      { billingAccount && (
         <Box width="100" maxWidth="9xl" gap="4xl" fontSize="sm">
      <Card>
        <Card.Header title="Billing Profile" />
        <Card.Section gap="xl">
          <LabelValue label="Account Name" value={billingAccount.name} />
          <LabelValue label="Phone" value={billingAccount.phone} />
          <LabelValue label="Email" value={billingAccount.email} />
          <LabelValue
            label="Address"
            value={
              <>
                <Box as="p">{billingAccount.address.streetAddress}</Box>
                <Box as="p">
                  {billingAccount.address.locality}, {billingAccount.address.region}{' '}
                  {billingAccount.address.postalCode}, {billingAccount.address.country}
                </Box>
              </>
            }
          />
        </Card.Section>
      </Card>
      {billingAccount.paymentMethods.length ? (
        <Card>
          <Card.Header title="Payment Method" />
          <Card.Section gap="xl">
            <LabelValue
              label={billingAccount.paymentMethods[0].type === 'Card' ? 'Credit Card' : 'Bank Account'}
              value={
                billingAccount.paymentMethods[0].type === 'Card'
                  ? `************${billingAccount.paymentMethods[0].last4}`
                  : '****************'
              }
            />
          </Card.Section>
        </Card>
      ) : (
        <Alert hasIcon variant="warning" title="No payment method found" />
      )}
    </Box>
      )}
    </>
  );
};
