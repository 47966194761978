import { CreateMemberBody, GetMembersBody } from '../types/members';
import { Member } from '../types/teams';
import { api } from './api';
import { ListResponse } from './types';

export const MembersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMembers: builder.query<ListResponse<Member>, GetMembersBody>({
      query: ({ organizationId, pageSize = 50, pageNum = 1, search, includeApiKeys = false }) => ({
        url: `api/organizations/${organizationId}/members?pageNum=${pageNum}&pageSize=${pageSize}&includeApiKeys=${includeApiKeys}${
          search ? `&search=${search}` : ''
        }`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [...result.data.map(({ id }) => ({ type: 'Members' as const, id: id })), { type: 'Members', id: 'LIST' }]
          : [],
    }),
    createMember: builder.mutation<Member, CreateMemberBody>({
      query: (member) => ({
        url: `api/organizations/${member.organizationId}/members`,
        method: 'POST',
        body: {
          firstName: member.firstName,
          lastName: member.lastName,
          email: member.email,
        },
      }),
      invalidatesTags: [{ type: 'Members', id: 'LIST' }],
    }),
  }),
});

export const { useGetMembersQuery, useLazyGetMembersQuery, useCreateMemberMutation } = MembersApi;
